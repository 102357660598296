<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
        <side-bar
            :background-color="sidebarBackground"
            short-title="SoiBear"
            title="SoiBear"
        >
            <template v-slot:links>
                <!-- <sidebar-item
                    :link="{
                        name: 'Dashboard',
                        icon: 'ni ni-tv-2 text-primary',
                        path: '/dashboard',
                    }"
                /> -->
                <sidebar-item
                    :link="{
                        name: 'Products',
                        icon: 'ni ni-box-2 text-green',
                        path: '/products',
                    }"
                />
                <sidebar-item
                    :link="{
                        name: 'Records',
                        icon: 'ni ni-bullet-list-67 text-blue',
                        path: '/records',
                    }"
                />
                <sidebar-item
                    :link="{
                        name: 'Orders',
                        icon: 'ni ni-pin-3 text-orange',
                        path: '/orders',
                    }"
                />
                <sidebar-item
                    :link="{
                        name: 'Create Order',
                        icon: 'fas fa-cart-plus text-green',
                        path: '/create',
                    }"
                />
                <sidebar-item
                    :link="{
                        name: 'User Profile',
                        icon: 'ni ni-single-02 text-yellow',
                        path: '/profile',
                    }"
                />
                <!-- <sidebar-item
                    :link="{
                        name: 'Icons',
                        icon: 'ni ni-planet text-blue',
                        path: '/icons',
                    }"
                /> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Maps',
                        icon: 'ni ni-pin-3 text-orange',
                        path: '/maps',
                    }"
                /> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Tables',
                        icon: 'ni ni-bullet-list-67 text-red',
                        path: '/tables',
                    }"
                /> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Login',
                        icon: 'ni ni-key-25 text-info',
                        path: '/login',
                    }"
                /> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Register',
                        icon: 'ni ni-circle-08 text-pink',
                        path: '/register',
                    }"
                /> -->
            </template>
        </side-bar>
        <div class="main-content" :data="sidebarBackground">
            <dashboard-navbar></dashboard-navbar>

            <div @click="toggleSidebar">
                <!-- your content here -->
                <router-view></router-view>
                <content-footer v-if="!$route.meta.hideFooter"></content-footer>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
    },
    data() {
        return {
            sidebarBackground: "vue", //vue|blue|orange|green|red|primary
        };
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
    },
};
</script>
<style lang="scss"></style>
