<template>
    <nav
        class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
        id="sidenav-main"
    >
        <div class="container-fluid">
            <!--Toggler-->
            <navbar-toggle-button @click="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="soibear" />
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <!-- <base-dropdown class="nav-item" position="right">
                        <template v-slot:title>
                            <a
                                class="nav-link nav-link-icon"
                                href="#"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i class="ni ni-bell-55"></i>
                            </a>
                        </template>

                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#"
                            >Something else here</a
                        >
                    </base-dropdown> -->
                    <base-dropdown class="nav-item" position="right">
                        <template v-slot:title>
                            <!-- <a class="nav-link" href="" role="button"> -->
                            <div class="media align-items-center">
                                <span class="avatar avatar-sm rounded-circle">
                                    <img
                                        alt="Image placeholder"
                                        src="img/theme/team-1-800x800.jpg"
                                    />
                                </span>
                            </div>
                            <!-- </a> -->
                        </template>

                        <div class="dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome!</h6>
                        </div>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-single-02"></i>
                            <span>My profile</span>
                        </router-link>
                        <!-- <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Settings</span>
                        </router-link> -->
                        <!-- <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-calendar-grid-58"></i>
                            <span>Activity</span>
                        </router-link> -->
                        <!-- <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-support-16"></i>
                            <span>Support</span>
                        </router-link> -->
                        <!-- <div class="dropdown-divider"></div> -->
                        <a href="#!" class="dropdown-item">
                            <i class="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                        </a>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div
                v-show="$sidebar.showSidebar"
                class="navbar-collapse collapse show"
                id="sidenav-collapse-main"
            >
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-10 collapse-brand">
                            <router-link to="/">
                                <img :src="logo" />
                            </router-link>
                        </div>
                        <div class="col-2 collapse-close">
                            <navbar-toggle-button
                                @click="closeSidebar"
                            ></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links"> </slot>
                </ul>
                <!--Divider-->
                <hr class="my-3" />
                <ul class="navbar-nav mb-md-3">
                    <li class="nav-item">
                        <div class="nav-link" @click.stop="logout">
                            <i class="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav mb-md-3">
                    <li class="nav-item">
                        <div class="nav-link" @click.stop="logoutAll">
                            <i class="fas fa-sign-out-alt"></i>
                            <span>Logout All Device</span>
                        </div>
                    </li>
                </ul>
                <!--Heading-->
                <!-- <h6 class="navbar-heading text-muted">Documentation</h6> -->
                <!--Navigation-->
                <!-- <ul class="navbar-nav mb-md-3">
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="https://demos.creative-tim.com/vue-argon-dashboard/documentation"
                        >
                            <i class="ni ni-spaceship"></i> Getting started
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/foundation/colors.html"
                        >
                            <i class="ni ni-palette"></i> Foundation
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/components/alerts.html"
                        >
                            <i class="ni ni-ui-04"></i> Components
                        </a>
                    </li>
                </ul> -->
            </div>
        </div>
    </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";

export default {
    name: "sidebar",
    components: {
        NavbarToggleButton,
    },
    props: {
        logo: {
            type: String,
            default: "https://i.imgur.com/El5WkrH.png",
            description: "Sidebar app logo",
        },
        autoClose: {
            type: Boolean,
            default: true,
            description:
                "Whether sidebar should autoclose on mobile when clicking an item",
        },
    },
    provide() {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        closeSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        showSidebar() {
            this.$sidebar.displaySidebar(true);
        },
        async logout() {
            await this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        async logoutAll() {
            await this.$store.dispatch("auth/logoutAll");
            this.$router.push("/login");
        },
    },
    beforeUnmount() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
};
</script>

<style scoped>
#sidenav-collapse-main > ul.navbar-nav.mb-md-3 > li > div:hover {
    cursor: pointer;
}

#sidenav-main > div > a > img {
    display: inline;
    transform: scale(2);
}

#sidenav-collapse-main > div > div > div.col-10.collapse-brand > a > img {
    display: block;
    margin: 0 auto;
    transform: scale(2);
}
</style>